<template>
  <div class="drawer-container">
    <NuxtLink v-for="drawer in mainDrawers" :key="drawer.id" class="drawer" :to="drawer.to">
      <img
        v-if="isComptalib() && drawer.iconV2"
        :src="getComptalibLink(drawer.iconV2)"
        class="drawer-icon"
      >
      <uds-icon v-else class="drawer-icon" :icon-name="drawer.icon" />
      <template v-if="!hasToggle">
        <span>{{ drawer.title }}</span>
        <uds-icon
          v-if="drawer.isLocked"
          :color="primaryColors.primary300"
          icon-name="lock"
          size="small"
          class="drawer-tag-icon"
        />
        <div v-else-if="drawer.newTag" class="drawer-tag">New</div>
        <uds-tooltip
          v-else-if="drawer.count && drawer.count > 0"
          :tooltip-text="drawer.countTooltip"
          style="margin-left: auto"
        >
          <div class="drawer-tag" @click.prevent="redirectToCounterLink(drawer.counterLink)">
            {{ drawer.count }}
          </div>
        </uds-tooltip>
      </template>
    </NuxtLink>
  </div>

  <div class="uds-divider" />

  <div class="drawer-container --sub">
    <NuxtLink v-for="drawer in subDrawers" :key="drawer.id" class="drawer" :to="drawer.to">
      <img
        v-if="isComptalib() && drawer.iconV2"
        :src="getComptalibLink(drawer.iconV2)"
        class="drawer-icon"
        :class="{
          '--increase': drawer.iconV2 === 'market' || drawer.iconV2 === 'partner-accountants',
        }"
      >
      <uds-icon v-else class="drawer-icon" :icon-name="drawer.icon" />
      <template v-if="!hasToggle">
        <span>{{ drawer.title }}</span>
      </template>
    </NuxtLink>
  </div>

  <div class="drawer-tools">
    <LayoutAccompaniement v-if="!isAdmin && !hasToggle && shouldDisplayAccompaniement" />
    <!-- TODO: Remove roleComposable after test -->
    <LayoutReport
      v-if="
        roleComposable.hasOneOfTheseRoles([Role.ADMIN, Role.DEVELOPER]) &&
        isClementine() &&
        !isAdmin &&
        !hasToggle
      "
    />
    <LayoutQuickUpload
      v-if="!isAdmin && !hasToggle && !permissionComposable.isFreemium() && isAccruals"
    />
  </div>
</template>

<script setup lang="ts">
import { Role } from "@silexpert/core";
import { filename } from "pathe/utils";
const { isComptalib, isClementine, isPourcentage, isComptaStart } = useBrandsComposable();
const societyStore = useSocietyStore();

const drawers = computed(() => useDrawersComposable().drawers);
const adminDrawers = computed(() => useDrawersComposable().adminDrawers);

const props = defineProps<{
  toggle: boolean;
  isAdmin: boolean;
}>();

const permissionComposable = usePermissionsComposable();
const prestationComposable = usePrestationsComposable();
const roleComposable = useRolesComposable();

const glob = import.meta.glob("@/assets/img/drawer/*.png", { eager: true });
const images = Object.fromEntries(
  // @ts-expect-error
  Object.entries(glob).map(([key, value]) => [filename(key), value.default]),
);

onMounted(() => {
  societyStore.fetchSocietyDetail();
});

const isAccruals = computed(() => societyStore.isAccruals);

const shouldDisplayAccompaniement = computed(
  () =>
    (isPourcentage() ||
      isClementine() ||
      (isComptaStart() && prestationComposable.hasSignedPaidAccountingPrestation())) &&
    !permissionComposable.isFreemium(),
);

function getComptalibLink(name: string) {
  return images?.[name] ?? "";
}

const primaryColors = computed(() => {
  return getPrimaryColors();
});

const hasToggle = computed(() => {
  return props.toggle;
});

const currentDrawers = computed(() => {
  return props.isAdmin ? adminDrawers : drawers;
});

const mainDrawers = computed(() => {
  return currentDrawers.value.value.filter((drawer) => {
    const isVisible = drawer.isVisible;
    return !drawer.subDrawer && isVisible;
  });
});

const subDrawers = computed(() => {
  return currentDrawers.value.value.filter((drawer) => {
    const isVisible = drawer.isVisible;
    return drawer.subDrawer && isVisible;
  });
});

function redirectToCounterLink(link: string | undefined) {
  if (isDefined(link)) {
    return navigateTo(link);
  }
}
</script>

<style lang="scss" scoped>
@use "@/assets/styles/drawers.scss";
.drawer-icon {
  margin-right: 15px;
  max-width: 20px;
  &.--increase {
    margin-left: -8px;
    max-width: 32px;
  }
}

.drawer-tools {
  // Add more padding to avoid crisp button to hide content
  padding-bottom: 80px;
}
</style>
