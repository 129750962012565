import payload_plugin_66M3YmQ91u from "/app/node_modules/.pnpm/@pinia+nuxt@0.8.0_magicast@0.3.5_pinia@2.3.0_typescript@5.7.2_vue@3.5.13_typescript@5.7.2___rollup@4.30.1/node_modules/@pinia/nuxt/dist/runtime/payload-plugin.js";
import revive_payload_client_TSADuCV99J from "/app/node_modules/.pnpm/nuxt@3.15.1_@parcel+watcher@2.5.0_@types+node@22.10.5_db0@0.2.1_eslint@9.18.0_jiti@2.4.2__ior_ax6c5abxb6okufd2xjob2ejoc4/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_SrSOUK7rxR from "/app/node_modules/.pnpm/nuxt@3.15.1_@parcel+watcher@2.5.0_@types+node@22.10.5_db0@0.2.1_eslint@9.18.0_jiti@2.4.2__ior_ax6c5abxb6okufd2xjob2ejoc4/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_vy2rPWgFkQ from "/app/node_modules/.pnpm/nuxt@3.15.1_@parcel+watcher@2.5.0_@types+node@22.10.5_db0@0.2.1_eslint@9.18.0_jiti@2.4.2__ior_ax6c5abxb6okufd2xjob2ejoc4/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_hasL273yj6 from "/app/node_modules/.pnpm/nuxt@3.15.1_@parcel+watcher@2.5.0_@types+node@22.10.5_db0@0.2.1_eslint@9.18.0_jiti@2.4.2__ior_ax6c5abxb6okufd2xjob2ejoc4/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_zj040qXa8A from "/app/node_modules/.pnpm/nuxt@3.15.1_@parcel+watcher@2.5.0_@types+node@22.10.5_db0@0.2.1_eslint@9.18.0_jiti@2.4.2__ior_ax6c5abxb6okufd2xjob2ejoc4/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_9PTP5F6SPO from "/app/node_modules/.pnpm/nuxt@3.15.1_@parcel+watcher@2.5.0_@types+node@22.10.5_db0@0.2.1_eslint@9.18.0_jiti@2.4.2__ior_ax6c5abxb6okufd2xjob2ejoc4/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_omVA9Vpkbx from "/app/node_modules/.pnpm/nuxt@3.15.1_@parcel+watcher@2.5.0_@types+node@22.10.5_db0@0.2.1_eslint@9.18.0_jiti@2.4.2__ior_ax6c5abxb6okufd2xjob2ejoc4/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_vue3_kuEolymiVn from "/app/node_modules/.pnpm/@pinia+nuxt@0.8.0_magicast@0.3.5_pinia@2.3.0_typescript@5.7.2_vue@3.5.13_typescript@5.7.2___rollup@4.30.1/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.js";
import components_plugin_KR1HBZs4kY from "/app/.nuxt/components.plugin.mjs";
import prefetch_client_Te9GydKph8 from "/app/node_modules/.pnpm/nuxt@3.15.1_@parcel+watcher@2.5.0_@types+node@22.10.5_db0@0.2.1_eslint@9.18.0_jiti@2.4.2__ior_ax6c5abxb6okufd2xjob2ejoc4/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_client_1kgqyPq7vF from "/app/node_modules/.pnpm/nuxt-gtag@3.0.2_magicast@0.3.5_rollup@4.30.1/node_modules/nuxt-gtag/dist/runtime/plugin.client.js";
import pwa_icons_plugin_LnrhIJeMG7 from "/app/.nuxt/pwa-icons-plugin.ts";
import pwa_client_YV7UHN4ZT0 from "/app/node_modules/.pnpm/@vite-pwa+nuxt@0.10.6_magicast@0.3.5_rollup@4.30.1_vite@6.0.7_@types+node@22.10.5_jiti@2.4.2__idvm65yeplhsa3sv7nvuw6angy/node_modules/@vite-pwa/nuxt/dist/runtime/plugins/pwa.client.js";
import plugin_zoF4yPIBkC from "/app/node_modules/.pnpm/nuxt-socket-io@3.0.13/node_modules/nuxt-socket-io/lib/plugin.js";
import plugin_wy0B721ODc from "/app/node_modules/.pnpm/@nuxtjs+device@3.2.4/node_modules/@nuxtjs/device/dist/runtime/plugin.js";
import plugin_client_3y9O7YglEU from "/app/node_modules/.pnpm/@samk-dev+nuxt-vcalendar@1.0.4_magicast@0.3.5_rollup@4.30.1_vue@3.5.13_typescript@5.7.2_/node_modules/@samk-dev/nuxt-vcalendar/dist/runtime/plugin.client.js";
import plugin_hm0W8doWkk from "/app/node_modules/.pnpm/pinia-plugin-persistedstate@4.2.0_@pinia+nuxt@0.8.0_magicast@0.3.5_pinia@2.3.0_typescript@5.7_6ihoazirkncphp4vbfkij5pkqe/node_modules/pinia-plugin-persistedstate/dist/nuxt/runtime/plugin.js";
import plugin_8UrCR0IazZ from "/app/node_modules/.pnpm/@sidebase+nuxt-auth@0.9.4_magicast@0.3.5_next-auth@4.21.1_next@13.5.8_@babel+core@7.26.0_reac_4n3vjbn3zmnmcgx6ygjpcaljsm/node_modules/@sidebase/nuxt-auth/dist/runtime/plugin.js";
import plugin_Dv3yfoisgS from "/app/node_modules/.pnpm/dayjs-nuxt@2.1.11_magicast@0.3.5_rollup@4.30.1/node_modules/dayjs-nuxt/dist/runtime/plugin.js";
import _01_brand_qsKxBO1fpE from "/app/plugins/01.brand.ts";
import _02_inject_dynamic_css_variables_client_UQ9uISccVp from "/app/plugins/02.inject-dynamic-css-variables.client.ts";
import _03_crisp_P9wmxnXAa7 from "/app/plugins/03.crisp.ts";
import _04_socket_yt7UzoOqjD from "/app/plugins/04.socket.ts";
import quill_editor_client_tupKYLQr8r from "/app/plugins/quill-editor.client.ts";
import sentry_3AyO8nEfhE from "/app/plugins/sentry.ts";
import universal_design_system_client_NoP6UuzpPW from "/app/plugins/universal-design-system.client.ts";
import vue_draggable_OKRqLUiGtd from "/app/plugins/vue-draggable.ts";
import vue_final_modal_pML93k5qcp from "/app/plugins/vue-final-modal.ts";
export default [
  payload_plugin_66M3YmQ91u,
  revive_payload_client_TSADuCV99J,
  unhead_SrSOUK7rxR,
  router_vy2rPWgFkQ,
  payload_client_hasL273yj6,
  navigation_repaint_client_zj040qXa8A,
  check_outdated_build_client_9PTP5F6SPO,
  chunk_reload_client_omVA9Vpkbx,
  plugin_vue3_kuEolymiVn,
  components_plugin_KR1HBZs4kY,
  prefetch_client_Te9GydKph8,
  plugin_client_1kgqyPq7vF,
  pwa_icons_plugin_LnrhIJeMG7,
  pwa_client_YV7UHN4ZT0,
  plugin_zoF4yPIBkC,
  plugin_wy0B721ODc,
  plugin_client_3y9O7YglEU,
  plugin_hm0W8doWkk,
  plugin_8UrCR0IazZ,
  plugin_Dv3yfoisgS,
  _01_brand_qsKxBO1fpE,
  _02_inject_dynamic_css_variables_client_UQ9uISccVp,
  _03_crisp_P9wmxnXAa7,
  _04_socket_yt7UzoOqjD,
  quill_editor_client_tupKYLQr8r,
  sentry_3AyO8nEfhE,
  universal_design_system_client_NoP6UuzpPW,
  vue_draggable_OKRqLUiGtd,
  vue_final_modal_pML93k5qcp
]