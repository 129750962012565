<template>
  <div class="report-wrapper">
    <div class="report-container">
      <div class="uds-headers --h4">Aidez-nous à améliorer votre expérience</div>
      <p class="uds-paragraph --small">Pour toute réclamation, nos équipes sont là pour vous !</p>
      <div class="--cta">
        <uds-main-button type="secondary" size="medium" @click="openAddReportModal()">
          Informez-nous
        </uds-main-button>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { useModal } from "vue-final-modal";
import { CommonsModalsAddReport } from "#components";

function openAddReportModal() {
  const { open, close } = useModal({
    component: CommonsModalsAddReport,
    attrs: {
      onClose() {
        close();
      },
      onCancel() {
        close();
      },
    },
  });
  open();
}
</script>

<style lang="scss" scoped>
.report-wrapper {
  margin-top: -$uds-spacing-2;
  padding: $uds-spacing-2;
  .report-container {
    border-radius: $uds-radius-1;
    background: $uds-white;
    box-shadow: 0px 4px 8px 0px rgba(51, 51, 51, 0.08);
    padding: $uds-spacing-2;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: $uds-spacing-2;

    p.uds-paragraph.--small {
      margin: 0;
    }

    .--cta {
      width: 100%;
      &:deep(button) {
        width: 100%;
      }
    }
  }
}
</style>
