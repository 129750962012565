import type { Cerfa2573_2024, ReadRegistration, ReadCerfa2573Inputs } from "@silexpert/core";
import { CerfaType } from "@silexpert/core";
import cloneDeep from "lodash-es/cloneDeep";
import type { Loadable } from "~/@types/localTypes/utils";

export type CompanyTaxAdvanceQueryProperties = {
  itemId: number | null | undefined;
  startDate: string | null;
  endDate: string | null;
  page: number | null;
  limit: number | null;
};

type CompanyTaxAdvanceForm = {
  idExercice: number | null;
  date: string | null;
  base: number | null;
  regularisation: number | null;
  currentRegistration: ReadRegistration | null;
};

type CompanyTaxRefundForm = {
  idExercice: number | null;
  payload: Partial<Cerfa2573_2024> | null;
  inputs: ReadCerfa2573Inputs | null;
};

type CompanyTaxAdvanceState = {
  queryProperties: CompanyTaxAdvanceQueryProperties;
  controllers: { fetch: AbortController | null };
  hasAnyItem: boolean | null;
  data: Array<ReadRegistration & Loadable>;
  maxPages: number | null;
  currentPage: number | null;
  totalItems: number | null;
  isLoading: boolean;
  form: CompanyTaxAdvanceForm;
  refundForm: CompanyTaxRefundForm;
};

export const defaultCompanyTaxAdvanceForm: CompanyTaxAdvanceForm = {
  idExercice: null,
  date: null,
  base: null,
  regularisation: null,
  currentRegistration: null,
};

export const defaultCompanyTaxAdvanceRefundForm: CompanyTaxRefundForm = {
  idExercice: null,
  payload: null,
  inputs: null,
};

export const defaultCompanyTaxAdvanceQueryProperties = ref<CompanyTaxAdvanceQueryProperties>({
  itemId: null,
  startDate: null,
  endDate: null,
  page: 1,
  limit: 25,
});

export const useCompanyTaxAdvanceStore = defineStore("companyTaxAdvance", {
  state: () =>
    ref<CompanyTaxAdvanceState>({
      queryProperties: cloneDeep(defaultCompanyTaxAdvanceQueryProperties.value),
      controllers: { fetch: null },
      data: [],
      hasAnyItem: null,
      maxPages: null,
      currentPage: null,
      totalItems: null,
      isLoading: false,
      form: cloneDeep(defaultCompanyTaxAdvanceForm),
      refundForm: cloneDeep(defaultCompanyTaxAdvanceRefundForm),
    }),
  actions: {
    reset() {
      this.controllers = { fetch: null };
      this.data = [];
      this.hasAnyItem = null;
      this.maxPages = null;
      this.currentPage = null;
      this.totalItems = null;
      this.isLoading = false;
      this.queryProperties = cloneDeep(defaultCompanyTaxAdvanceQueryProperties.value);
      this.form = cloneDeep(defaultCompanyTaxAdvanceForm);
      this.refundForm = cloneDeep(defaultCompanyTaxAdvanceRefundForm);
    },
    async fetch() {
      const societyStore = useSocietyStore();
      try {
        const { startDate, endDate, page, limit } = this.queryProperties;

        const exerciceStore = useExerciceStore();

        const currentExercice = exerciceStore.exercices.find(
          (e) => e.startDate === startDate && e.endDate === endDate,
        );

        if (!currentExercice)
          return $notifier().open({
            type: "error",
            content:
              "Veuillez sélectionner une période correspondant à un exercice entier de la société",
          });

        if (this.controllers.fetch && this.controllers.fetch.signal) {
          await this.controllers.fetch.abort();
          this.controllers.fetch = null;
        }
        this.controllers.fetch = new AbortController();

        this.isLoading = true;

        const response = await $silex().registration.getPaginated(
          societyStore.society!.id!,
          {
            idExercice: currentExercice.id,
            page: page ?? 1,
            limit: limit ?? 25,
            idCerfaType: [
              CerfaType.CERFA_2571_2023_A,
              CerfaType.CERFA_2571_2023_B,
              CerfaType.CERFA_2571_2023_C,
              CerfaType.CERFA_2571_2023_D,
              CerfaType.CERFA_2573,
            ],
          },
          this.controllers.fetch.signal,
        );

        this.maxPages = response.maxPages;
        this.currentPage = response.currentPage;
        this.totalItems = response.totalItems;
        this.hasAnyItem = response.totalItems > 0;
        this.data = response.data.map((d) => ({ ...d, isLoading: false }));
      } catch (error: any) {
        if (error?.code === "ERR_CANCELED" || error?.message === "canceled") return;
        $notifier().open({ type: "error", content: apiErrorToString(error) });
      } finally {
        this.isLoading = false;
      }
    },
  },
});
