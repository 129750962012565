<template>
  <div class="help-menu-container">
    <div class="help-menu__header">
      <span class="help-menu__text--title">Besoin d’aide ?</span>
      <span class="help-menu__text--subtitle">Nous sommes là pour vous accompagner.</span>
    </div>

    <div class="help-menu__body">
      <div class="help-menu__list">
        <div class="help-menu__item help-menu__action" @click="openHelpLink">
          <div class="help-menu__item-title">
            <uds-icon icon-name="help_outline" :color="udsColors.udsNeutral800" />
            <span>Consulter le centre d’aide</span>
          </div>
          <uds-icon icon-name="open_in_new" :color="udsColors.udsNeutral800" />
        </div>

        <div v-if="isClementine()" class="help-menu__item help-menu__action" @click="openClemBlog">
          <div class="help-menu__item-title">
            <uds-icon icon-name="menu_book" :color="udsColors.udsNeutral800" />
            <span>Consulter notre blog</span>
          </div>
          <uds-icon icon-name="open_in_new" :color="udsColors.udsNeutral800" />
        </div>

        <div v-if="isComptalib()" class="help-menu__item help-menu__action" @click="openFbGroup">
          <div class="help-menu__item-title">
            <uds-icon icon-name="people" :color="udsColors.udsNeutral800" />
            <span> Échanger avec la communauté</span>
          </div>
          <uds-icon icon-name="open_in_new" :color="udsColors.udsNeutral800" />
        </div>

        <div
          v-if="!isComptalib() && shouldDisplayAccountantAppointment"
          class="help-menu__item help-menu__action"
          @click="openCalendlyAppointment"
        >
          <div class="help-menu__item-title">
            <uds-icon icon-name="work" :color="udsColors.udsNeutral800" />
            <span>Prendre rendez-vous </span>
          </div>
          <uds-icon icon-name="chevron_right" :color="udsColors.udsNeutral800" />
        </div>
      </div>

      <div class="help-menu__footer">
        <div v-if="!isComptalib()" class="help-menu__contact contact-caller">
          <uds-icon icon-name="call" :color="primaryColor" />
          <span>03 83 93 14 14</span>
          <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <mask
              id="mask0_2512_398"
              style="mask-type: alpha"
              maskUnits="userSpaceOnUse"
              x="0"
              y="0"
              width="24"
              height="24"
            >
              <circle cx="12" cy="12" r="12" fill="#D9D9D9" />
            </mask>
            <g mask="url(#mask0_2512_398)">
              <rect width="8" height="24" fill="#002395" />
              <rect x="8" width="8" height="24" fill="white" />
              <rect x="16" width="8" height="24" fill="#ED2939" />
            </g>
          </svg>
          <span>Gratuit</span>
        </div>
        <span class="help-menu__info">
          Notre équipe est disponible du lundi au vendredi de 9h à {{ endHour }}.
        </span>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
const { isComptalib, isClementine, isComptaStart, isPourcentage } = useBrandsComposable();

const accountantComposable = useAccountantComposable();
const permissionComposable = usePermissionsComposable();
const prestationComposable = usePrestationsComposable();

const endHour = computed(() => (isPourcentage() ? "18h" : "19h"));

const primaryColor = computed(() => {
  return getPrimaryColors().primary500;
});
const udsColors = getUdsColors();

const shouldDisplayAccountantAppointment = computed(
  () =>
    ((isComptaStart() && prestationComposable.hasSignedPaidAccountingPrestation()) ||
      !isComptalib()) &&
    !permissionComposable.isFreemium(),
);

function openCalendlyAppointment() {
  accountantComposable.openCalendlyAppointment({ withoutType: true });
}

function openHelpLink() {
  const url = isComptalib()
    ? "https://help.comptalib.com/fr/"
    : "https://www.compta-clementine.fr/aide/";

  return navigateTo(url, { external: true, open: { target: "_blank" } });
}

function openFbGroup() {
  return navigateTo("https://www.facebook.com/groups/616334702397087", {
    external: true,
    open: { target: "_blank" },
  });
}

function openClemBlog() {
  return navigateTo("https://www.clementine.fr/blog/", {
    external: true,
    open: { target: "_blank" },
  });
}
</script>

<style lang="scss" scoped>
.help-menu-container {
  cursor: default;
  width: inherit;

  box-shadow:
    0px 5px 5px -3px rgba(0, 0, 0, 0.2),
    0px 8px 10px 1px rgba(0, 0, 0, 0.14),
    0px 3px 14px 2px rgba(0, 0, 0, 0.12);
  border-radius: $uds-spacing-1;

  min-width: 400px;
  min-height: 363px;
  overflow-y: visible;

  background: $uds-white;
}

.help-menu__header {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-left: $uds-spacing-2;
  background-color: $uds-primary-500;
  color: $uds-white;
  height: 70px;

  .help-menu__back {
    display: flex;

    .back-icon {
      display: flex;
      align-items: center;
      justify-content: center;
      width: $uds-spacing-3;
      height: $uds-spacing-3;
      border-radius: 100%;
      margin-right: 15px;
      background-color: $uds-white;
      cursor: pointer;
    }
  }

  .help-menu__text {
    &--title {
      font-weight: $uds-weight-500;
      font-size: 16px;
    }
    &--subtitle {
      font-size: 14px;
    }
  }
}

.help-menu__body {
  padding: $uds-spacing-2;

  .help-menu__action {
    cursor: pointer;
  }

  .help-menu__list {
    margin-bottom: $uds-spacing-3;

    .help-menu__accountant {
      display: flex;
      flex-direction: row;
      margin-top: 20px;

      .accountant__picture {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 81px;
        width: 81px;
        border-radius: 100%;
      }
      .accountant__identity {
        display: flex;
        flex-direction: column;
        justify-content: center;
        margin-left: $uds-spacing-2;
        &--name {
          color: $uds-primary-500;
          font-weight: $uds-weight-500;
          font-size: 16px;
        }
        &--email {
          font-size: 14px;
          color: $uds-neutral-900;
        }

        &--phone {
          font-size: 14px;
          color: $uds-neutral-900;
        }

        &--working-schedules {
          font-size: 14px;
          color: $uds-neutral-800;
          max-width: 370px;
        }
      }
    }
  }

  .help-menu__item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 42px;
    margin-bottom: $uds-spacing-1;
    border-radius: 5px;
    padding: 9px $uds-spacing-3;
    background-color: $uds-neutral-100;

    .help-menu__item-title {
      display: flex;
      align-items: center;

      span {
        margin-left: 10px;
      }
    }
  }

  .help-menu__footer {
    display: flex;
    flex-direction: column;
    align-items: center;
    .help-menu__contact {
      display: flex;
      align-items: center;
      width: 260px;
      height: 40px;
      border-radius: $uds-spacing-1;
      background-color: $uds-primary-50;
      font-size: 16px;

      &.contact-caller {
        justify-content: space-evenly;
      }

      span {
        color: $uds-primary-500;
      }

      .icon-phone {
        color: $uds-primary-500;
      }
    }
    .help-menu__info {
      margin-top: $uds-spacing-1;
      text-align: center;
      width: 85%;

      .info-link {
        color: $uds-primary-500;
        text-decoration: underline;
        cursor: pointer;
      }
    }
  }
}
</style>
