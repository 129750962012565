
if (import.meta.hot) {
  import.meta.hot.accept((mod) => {
    const router = import.meta.hot.data.router
    const generateRoutes = import.meta.hot.data.generateRoutes
    if (!router || !generateRoutes) {
      import.meta.hot.invalidate('[nuxt] Cannot replace routes because there is no active router. Reloading.')
      return
    }
    router.clearRoutes()
    const routes = generateRoutes(mod.default || mod)
    function addRoutes (routes) {
      for (const route of routes) {
        router.addRoute(route)
      }
      router.replace('')
    }
    if (routes && 'then' in routes) {
      routes.then(addRoutes)
    } else {
      addRoutes(routes)
    }
  })
}

export function handleHotUpdate(_router, _generateRoutes) {
  if (import.meta.hot) {
    import.meta.hot.data ||= {}
    import.meta.hot.data.router = _router
    import.meta.hot.data.generateRoutes = _generateRoutes
  }
}
import { default as acceptance1XwLWMa8StMeta } from "/app/pages/acceptance.vue?macro=true";
import { default as genericaccountkWuf6Iz02qMeta } from "/app/pages/admin/genericaccount.vue?macro=true";
import { default as performancesMMt67OVRz1Meta } from "/app/pages/admin/performances.vue?macro=true";
import { default as customerstGOQpQ6CyXMeta } from "/app/pages/admin/societies/[id]/accounting/customers.vue?macro=true";
import { default as documentsprjk6VEzmXMeta } from "/app/pages/admin/societies/[id]/accounting/documents.vue?macro=true";
import { default as pendingpointsi59ytQHp74Meta } from "/app/pages/admin/societies/[id]/accounting/pendingpoints.vue?macro=true";
import { default as providersgxgStRXtLaMeta } from "/app/pages/admin/societies/[id]/accounting/providers.vue?macro=true";
import { default as revisionaccountINdkYgmOVAMeta } from "/app/pages/admin/societies/[id]/accounting/revisionaccount.vue?macro=true";
import { default as revisionguideL8sZweJehEMeta } from "/app/pages/admin/societies/[id]/accounting/revisionguide.vue?macro=true";
import { default as persodAtNZZBrv5Meta } from "/app/pages/admin/societies/[id]/config/accountplan/perso.vue?macro=true";
import { default as specificwWLgG93p8YMeta } from "/app/pages/admin/societies/[id]/config/accountplan/specific.vue?macro=true";
import { default as bankr9L4Xk8MtGMeta } from "/app/pages/admin/societies/[id]/config/bank.vue?macro=true";
import { default as exportZxljm6WjJ0Meta } from "/app/pages/admin/societies/[id]/config/export.vue?macro=true";
import { default as informationTO0rFMTMcBMeta } from "/app/pages/admin/societies/[id]/config/information.vue?macro=true";
import { default as invoiceskiD6eM6IubMeta } from "/app/pages/admin/societies/[id]/config/invoices.vue?macro=true";
import { default as precomptad5CJYoy03eMeta } from "/app/pages/admin/societies/[id]/config/precompta.vue?macro=true";
import { default as usersrXz7XeXLf2Meta } from "/app/pages/admin/societies/[id]/config/users.vue?macro=true";
import { default as annexARhKlE2tqdMeta } from "/app/pages/admin/societies/[id]/declaration/annex.vue?macro=true";
import { default as balancesheetUH5DNsOROuMeta } from "/app/pages/admin/societies/[id]/declaration/balancesheet.vue?macro=true";
import { default as formVfScXPyqpLMeta } from "/app/pages/admin/societies/[id]/declaration/companyTaxAdvances/form.vue?macro=true";
import { default as indexXtaYhJQjspMeta } from "/app/pages/admin/societies/[id]/declaration/companyTaxAdvances/index.vue?macro=true";
import { default as refundW748j8wzmJMeta } from "/app/pages/admin/societies/[id]/declaration/companyTaxAdvances/refund.vue?macro=true";
import { default as das2XBaXY2s2UKMeta } from "/app/pages/admin/societies/[id]/declaration/das2.vue?macro=true";
import { default as vat7zdfVuGjBEMeta } from "/app/pages/admin/societies/[id]/declaration/vat.vue?macro=true";
import { default as indexPoMcL32fEcMeta } from "/app/pages/admin/societies/index.vue?macro=true";
import { default as admin0eiku7Jj1aMeta } from "/app/pages/admin.vue?macro=true";
import { default as annotateAfterImportTFuDamQmUAMeta } from "/app/pages/annotateAfterImport.vue?macro=true";
import { default as login6pALODa45CMeta } from "/app/pages/app/auth/login.vue?macro=true";
import { default as appointmentuvDj7nIaCwMeta } from "/app/pages/appointment.vue?macro=true";
import { default as indexnN54mmutzrMeta } from "/app/pages/autoLogin/index.vue?macro=true";
import { default as autoLoginEioHw3XAmYMeta } from "/app/pages/autoLogin.vue?macro=true";
import { default as balanceiAfK5AoXNpMeta } from "/app/pages/balancesheet/balance.vue?macro=true";
import { default as generalLedger5TzfSyuJs6Meta } from "/app/pages/balancesheet/generalLedger.vue?macro=true";
import { default as indexFemvvYzn7zMeta } from "/app/pages/balancesheet/index.vue?macro=true";
import { default as balancesheetTqec5yjklAMeta } from "/app/pages/balancesheet.vue?macro=true";
import { default as index68Wuf4XWWGMeta } from "/app/pages/banks/index.vue?macro=true";
import { default as banksvJUn3EPXWRMeta } from "/app/pages/banks.vue?macro=true";
import { default as das2QR93y75jBQMeta } from "/app/pages/das2.vue?macro=true";
import { default as indexhg2nWJrbRRMeta } from "/app/pages/dashboard/index.vue?macro=true";
import { default as dashboardJu37u7k0toMeta } from "/app/pages/dashboard.vue?macro=true";
import { default as _91id_93uvDjQXrx7YMeta } from "/app/pages/employees/form/[id].vue?macro=true";
import { default as freemiumWpLNa7xO5mMeta } from "/app/pages/employees/freemium.vue?macro=true";
import { default as indexkkok1ZfstGMeta } from "/app/pages/employees/index.vue?macro=true";
import { default as employeesbB5UFJ3ERiMeta } from "/app/pages/employees.vue?macro=true";
import { default as notAllowedpMtsqove2LMeta } from "/app/pages/errors/notAllowed.vue?macro=true";
import { default as clientsoByQTDRcEvMeta } from "/app/pages/expert/clients.vue?macro=true";
import { default as facturespCnEZVO1XMMeta } from "/app/pages/expert/factures.vue?macro=true";
import { default as performances6yguEIK3oOMeta } from "/app/pages/expert/performances.vue?macro=true";
import { default as completepefmZrM3Y8Meta } from "/app/pages/folder/complete.vue?macro=true";
import { default as indexvT9hGtrLxSMeta } from "/app/pages/forgot/index.vue?macro=true";
import { default as forgotNziWY5IfaeMeta } from "/app/pages/forgot.vue?macro=true";
import { default as balancesheetB40QHKeDd0Meta } from "/app/pages/freemium/balancesheet.vue?macro=true";
import { default as indexN6pT4Un8hYMeta } from "/app/pages/index.vue?macro=true";
import { default as index9oJbaMdJKOMeta } from "/app/pages/juridical/index.vue?macro=true";
import { default as juridical3rL9bBYHCSMeta } from "/app/pages/juridical.vue?macro=true";
import { default as indexxWYC1tMXRbMeta } from "/app/pages/library/index.vue?macro=true";
import { default as libraryLAIl2gJcEoMeta } from "/app/pages/library.vue?macro=true";
import { default as indexgK5VicK7y2Meta } from "/app/pages/login/index.vue?macro=true";
import { default as loginhHM0vSTW5jMeta } from "/app/pages/login.vue?macro=true";
import { default as _91id_93S0JA92ohenMeta } from "/app/pages/partner-accountants/[id].vue?macro=true";
import { default as indexLFNBof5ua8Meta } from "/app/pages/partner-accountants/index.vue?macro=true";
import { default as _91id_93XvPRuS5KCSMeta } from "/app/pages/partners/[id].vue?macro=true";
import { default as indexLG3Wtz8U6fMeta } from "/app/pages/partners/index.vue?macro=true";
import { default as partnersEg1L314k67Meta } from "/app/pages/partners.vue?macro=true";
import { default as indexZu37Q4vm8IMeta } from "/app/pages/payment/index.vue?macro=true";
import { default as previsional_45balance1obdoJeiVYMeta } from "/app/pages/previsional-balance.vue?macro=true";
import { default as indexUVclAWCcpcMeta } from "/app/pages/purchases/auxiliaryLedger/index.vue?macro=true";
import { default as indexKyZKoHUxJSMeta } from "/app/pages/purchases/expense-notes/index.vue?macro=true";
import { default as index6i7P1RXK56Meta } from "/app/pages/purchases/index.vue?macro=true";
import { default as index9JAOAZxtshMeta } from "/app/pages/purchases/providers/index.vue?macro=true";
import { default as purchasesw2WIicYsI7Meta } from "/app/pages/purchases.vue?macro=true";
import { default as registration_45companyDBi9UNHdZyMeta } from "/app/pages/registration-company.vue?macro=true";
import { default as reset_45password52TOS9kgCUMeta } from "/app/pages/reset-password.vue?macro=true";
import { default as indexujwFIL3o1vMeta } from "/app/pages/sales/auxiliaryLedger/index.vue?macro=true";
import { default as indexsRHY3vY8juMeta } from "/app/pages/sales/customers/index.vue?macro=true";
import { default as _91id_93xVIX6cb4UqMeta } from "/app/pages/sales/form/[id].vue?macro=true";
import { default as indexgi1M0XIQhpMeta } from "/app/pages/sales/index.vue?macro=true";
import { default as indexWnDkE5iRUkMeta } from "/app/pages/sales/payments/index.vue?macro=true";
import { default as indexPJvvGlOZZUMeta } from "/app/pages/sales/products/index.vue?macro=true";
import { default as indexwFrhiYUOGWMeta } from "/app/pages/sales/recovery/index.vue?macro=true";
import { default as indexroJCBzNZfKMeta } from "/app/pages/sales/ztickets/index.vue?macro=true";
import { default as saleszmwAXRzZQBMeta } from "/app/pages/sales.vue?macro=true";
import { default as indexIgnnyu3SV3Meta } from "/app/pages/settings/access/index.vue?macro=true";
import { default as indexPanEfpg3PuMeta } from "/app/pages/settings/annotations/index.vue?macro=true";
import { default as indexWkUZbUXh8BMeta } from "/app/pages/settings/banks/index.vue?macro=true";
import { default as manageCVqIDiYZgfMeta } from "/app/pages/settings/banks/manage.vue?macro=true";
import { default as customimgmJi6t9uMeta } from "/app/pages/settings/billing/custom.vue?macro=true";
import { default as indexAv8nybvrTZMeta } from "/app/pages/settings/billing/index.vue?macro=true";
import { default as indexTEo2UDeQwtMeta } from "/app/pages/settings/chart-accounts/index.vue?macro=true";
import { default as indexbe3eSQaWM7Meta } from "/app/pages/settings/ecommerce/[idConnector]/connexion/index.vue?macro=true";
import { default as indexvtmHd2yNMCMeta } from "/app/pages/settings/ecommerce/[idConnector]/installation/index.vue?macro=true";
import { default as indexyxJUqdmvokMeta } from "/app/pages/settings/ecommerce/index.vue?macro=true";
import { default as indexMtUQDZVaamMeta } from "/app/pages/settings/exports/index.vue?macro=true";
import { default as index11eXGkSJBcMeta } from "/app/pages/settings/index.vue?macro=true";
import { default as index9E1PXOBFSlMeta } from "/app/pages/settings/informations/index.vue?macro=true";
import { default as indexdfq5ievxEYMeta } from "/app/pages/settings/purchase-mail/index.vue?macro=true";
import { default as indexfltVc82Sf6Meta } from "/app/pages/settings/subscription/index.vue?macro=true";
import { default as settingsLwEYOlkQxrMeta } from "/app/pages/settings.vue?macro=true";
import { default as _91slug_9364l1QSPxIIMeta } from "/app/pages/shop/services/[slug].vue?macro=true";
import { default as indexgRArA1HI9yMeta } from "/app/pages/shop/services/index.vue?macro=true";
import { default as shopjA8q7oHQTMMeta } from "/app/pages/shop.vue?macro=true";
import { default as ssoVLRRZJLQ6pMeta } from "/app/pages/sso.vue?macro=true";
import { default as confirmedLOxpKCDaeTMeta } from "/app/pages/user/confirmed.vue?macro=true";
import { default as formquVYYZ2rAXMeta } from "/app/pages/vat/annex/form.vue?macro=true";
import { default as indexnK4pNWvol8Meta } from "/app/pages/vat/annex/index.vue?macro=true";
import { default as formOyR4k8EcjhMeta } from "/app/pages/vat/das2/form.vue?macro=true";
import { default as indexou4jplrXnHMeta } from "/app/pages/vat/das2/index.vue?macro=true";
import { default as _91idvat_93FmWymG5Fr5Meta } from "/app/pages/vat/details/[idvat].vue?macro=true";
import { default as formGXFynL9AvJMeta } from "/app/pages/vat/form.vue?macro=true";
import { default as indexx4b9KVWT46Meta } from "/app/pages/vat/index.vue?macro=true";
import { default as vatfjWmeHf5yhMeta } from "/app/pages/vat.vue?macro=true";
import { default as component_45stubsRK4GL8I6nMeta } from "/app/node_modules/.pnpm/nuxt@3.15.1_@parcel+watcher@2.5.0_@types+node@22.10.5_db0@0.2.1_eslint@9.18.0_jiti@2.4.2__ior_ax6c5abxb6okufd2xjob2ejoc4/node_modules/nuxt/dist/pages/runtime/component-stub.js?macro=true";
import { default as component_45stubsRK4GL8I6n } from "/app/node_modules/.pnpm/nuxt@3.15.1_@parcel+watcher@2.5.0_@types+node@22.10.5_db0@0.2.1_eslint@9.18.0_jiti@2.4.2__ior_ax6c5abxb6okufd2xjob2ejoc4/node_modules/nuxt/dist/pages/runtime/component-stub.js";
export default [
  {
    name: "acceptance",
    path: "/acceptance",
    meta: acceptance1XwLWMa8StMeta || {},
    component: () => import("/app/pages/acceptance.vue")
  },
  {
    name: "admin",
    path: "/admin",
    component: () => import("/app/pages/admin.vue"),
    children: [
  {
    name: "admin-genericaccount",
    path: "genericaccount",
    meta: genericaccountkWuf6Iz02qMeta || {},
    component: () => import("/app/pages/admin/genericaccount.vue")
  },
  {
    name: "admin-performances",
    path: "performances",
    meta: performancesMMt67OVRz1Meta || {},
    component: () => import("/app/pages/admin/performances.vue")
  },
  {
    name: "admin-societies-id-accounting-customers",
    path: "societies/:id()/accounting/customers",
    meta: customerstGOQpQ6CyXMeta || {},
    component: () => import("/app/pages/admin/societies/[id]/accounting/customers.vue")
  },
  {
    name: "admin-societies-id-accounting-documents",
    path: "societies/:id()/accounting/documents",
    meta: documentsprjk6VEzmXMeta || {},
    component: () => import("/app/pages/admin/societies/[id]/accounting/documents.vue")
  },
  {
    name: "admin-societies-id-accounting-pendingpoints",
    path: "societies/:id()/accounting/pendingpoints",
    meta: pendingpointsi59ytQHp74Meta || {},
    component: () => import("/app/pages/admin/societies/[id]/accounting/pendingpoints.vue")
  },
  {
    name: "admin-societies-id-accounting-providers",
    path: "societies/:id()/accounting/providers",
    meta: providersgxgStRXtLaMeta || {},
    component: () => import("/app/pages/admin/societies/[id]/accounting/providers.vue")
  },
  {
    name: "admin-societies-id-accounting-revisionaccount",
    path: "societies/:id()/accounting/revisionaccount",
    meta: revisionaccountINdkYgmOVAMeta || {},
    component: () => import("/app/pages/admin/societies/[id]/accounting/revisionaccount.vue")
  },
  {
    name: "admin-societies-id-accounting-revisionguide",
    path: "societies/:id()/accounting/revisionguide",
    meta: revisionguideL8sZweJehEMeta || {},
    component: () => import("/app/pages/admin/societies/[id]/accounting/revisionguide.vue")
  },
  {
    name: "admin-societies-id-config-accountplan-perso",
    path: "societies/:id()/config/accountplan/perso",
    meta: persodAtNZZBrv5Meta || {},
    component: () => import("/app/pages/admin/societies/[id]/config/accountplan/perso.vue")
  },
  {
    name: "admin-societies-id-config-accountplan-specific",
    path: "societies/:id()/config/accountplan/specific",
    meta: specificwWLgG93p8YMeta || {},
    component: () => import("/app/pages/admin/societies/[id]/config/accountplan/specific.vue")
  },
  {
    name: "admin-societies-id-config-bank",
    path: "societies/:id()/config/bank",
    meta: bankr9L4Xk8MtGMeta || {},
    component: () => import("/app/pages/admin/societies/[id]/config/bank.vue")
  },
  {
    name: "admin-societies-id-config-export",
    path: "societies/:id()/config/export",
    meta: exportZxljm6WjJ0Meta || {},
    component: () => import("/app/pages/admin/societies/[id]/config/export.vue")
  },
  {
    name: "admin-societies-id-config-information",
    path: "societies/:id()/config/information",
    meta: informationTO0rFMTMcBMeta || {},
    component: () => import("/app/pages/admin/societies/[id]/config/information.vue")
  },
  {
    name: "admin-societies-id-config-invoices",
    path: "societies/:id()/config/invoices",
    meta: invoiceskiD6eM6IubMeta || {},
    component: () => import("/app/pages/admin/societies/[id]/config/invoices.vue")
  },
  {
    name: "admin-societies-id-config-precompta",
    path: "societies/:id()/config/precompta",
    meta: precomptad5CJYoy03eMeta || {},
    component: () => import("/app/pages/admin/societies/[id]/config/precompta.vue")
  },
  {
    name: "admin-societies-id-config-users",
    path: "societies/:id()/config/users",
    meta: usersrXz7XeXLf2Meta || {},
    component: () => import("/app/pages/admin/societies/[id]/config/users.vue")
  },
  {
    name: "admin-societies-id-declaration-annex",
    path: "societies/:id()/declaration/annex",
    meta: annexARhKlE2tqdMeta || {},
    component: () => import("/app/pages/admin/societies/[id]/declaration/annex.vue")
  },
  {
    name: "admin-societies-id-declaration-balancesheet",
    path: "societies/:id()/declaration/balancesheet",
    meta: balancesheetUH5DNsOROuMeta || {},
    component: () => import("/app/pages/admin/societies/[id]/declaration/balancesheet.vue")
  },
  {
    name: "admin-societies-id-declaration-companyTaxAdvances-form",
    path: "societies/:id()/declaration/companyTaxAdvances/form",
    meta: formVfScXPyqpLMeta || {},
    component: () => import("/app/pages/admin/societies/[id]/declaration/companyTaxAdvances/form.vue")
  },
  {
    name: "admin-societies-id-declaration-companyTaxAdvances",
    path: "societies/:id()/declaration/companyTaxAdvances",
    meta: indexXtaYhJQjspMeta || {},
    component: () => import("/app/pages/admin/societies/[id]/declaration/companyTaxAdvances/index.vue")
  },
  {
    name: "admin-societies-id-declaration-companyTaxAdvances-refund",
    path: "societies/:id()/declaration/companyTaxAdvances/refund",
    meta: refundW748j8wzmJMeta || {},
    component: () => import("/app/pages/admin/societies/[id]/declaration/companyTaxAdvances/refund.vue")
  },
  {
    name: "admin-societies-id-declaration-das2",
    path: "societies/:id()/declaration/das2",
    meta: das2XBaXY2s2UKMeta || {},
    component: () => import("/app/pages/admin/societies/[id]/declaration/das2.vue")
  },
  {
    name: "admin-societies-id-declaration-vat",
    path: "societies/:id()/declaration/vat",
    meta: vat7zdfVuGjBEMeta || {},
    component: () => import("/app/pages/admin/societies/[id]/declaration/vat.vue")
  },
  {
    name: "admin-societies",
    path: "societies",
    meta: indexPoMcL32fEcMeta || {},
    component: () => import("/app/pages/admin/societies/index.vue")
  }
]
  },
  {
    name: "annotateAfterImport",
    path: "/annotateAfterImport",
    meta: annotateAfterImportTFuDamQmUAMeta || {},
    component: () => import("/app/pages/annotateAfterImport.vue")
  },
  {
    name: "app-auth-login",
    path: "/app/auth/login",
    meta: login6pALODa45CMeta || {},
    component: () => import("/app/pages/app/auth/login.vue")
  },
  {
    name: "appointment",
    path: "/appointment",
    meta: appointmentuvDj7nIaCwMeta || {},
    component: () => import("/app/pages/appointment.vue")
  },
  {
    name: autoLoginEioHw3XAmYMeta?.name,
    path: "/autoLogin",
    component: () => import("/app/pages/autoLogin.vue"),
    children: [
  {
    name: "autoLogin",
    path: "",
    meta: indexnN54mmutzrMeta || {},
    component: () => import("/app/pages/autoLogin/index.vue")
  }
]
  },
  {
    name: balancesheetTqec5yjklAMeta?.name,
    path: "/balancesheet",
    component: () => import("/app/pages/balancesheet.vue"),
    children: [
  {
    name: "balancesheet-balance",
    path: "balance",
    meta: balanceiAfK5AoXNpMeta || {},
    component: () => import("/app/pages/balancesheet/balance.vue")
  },
  {
    name: "balancesheet-generalLedger",
    path: "generalLedger",
    component: () => import("/app/pages/balancesheet/generalLedger.vue")
  },
  {
    name: "balancesheet",
    path: "",
    component: () => import("/app/pages/balancesheet/index.vue")
  }
]
  },
  {
    name: banksvJUn3EPXWRMeta?.name,
    path: "/banks",
    component: () => import("/app/pages/banks.vue"),
    children: [
  {
    name: "banks",
    path: "",
    meta: index68Wuf4XWWGMeta || {},
    component: () => import("/app/pages/banks/index.vue")
  }
]
  },
  {
    name: "das2",
    path: "/das2",
    component: () => import("/app/pages/das2.vue")
  },
  {
    name: dashboardJu37u7k0toMeta?.name,
    path: "/dashboard",
    component: () => import("/app/pages/dashboard.vue"),
    children: [
  {
    name: "dashboard",
    path: "",
    meta: indexhg2nWJrbRRMeta || {},
    component: () => import("/app/pages/dashboard/index.vue")
  }
]
  },
  {
    name: employeesbB5UFJ3ERiMeta?.name,
    path: "/employees",
    component: () => import("/app/pages/employees.vue"),
    children: [
  {
    name: "employees-form-id",
    path: "form/:id()",
    meta: _91id_93uvDjQXrx7YMeta || {},
    component: () => import("/app/pages/employees/form/[id].vue")
  },
  {
    name: "employees-freemium",
    path: "freemium",
    component: () => import("/app/pages/employees/freemium.vue")
  },
  {
    name: "employees",
    path: "",
    meta: indexkkok1ZfstGMeta || {},
    component: () => import("/app/pages/employees/index.vue")
  }
]
  },
  {
    name: "errors-notAllowed",
    path: "/errors/notAllowed",
    component: () => import("/app/pages/errors/notAllowed.vue")
  },
  {
    name: "expert-clients",
    path: "/expert/clients",
    meta: clientsoByQTDRcEvMeta || {},
    component: () => import("/app/pages/expert/clients.vue")
  },
  {
    name: "expert-factures",
    path: "/expert/factures",
    meta: facturespCnEZVO1XMMeta || {},
    component: () => import("/app/pages/expert/factures.vue")
  },
  {
    name: "expert-performances",
    path: "/expert/performances",
    meta: performances6yguEIK3oOMeta || {},
    component: () => import("/app/pages/expert/performances.vue")
  },
  {
    name: "folder-complete",
    path: "/folder/complete",
    component: () => import("/app/pages/folder/complete.vue")
  },
  {
    name: forgotNziWY5IfaeMeta?.name,
    path: "/forgot",
    component: () => import("/app/pages/forgot.vue"),
    children: [
  {
    name: "forgot",
    path: "",
    meta: indexvT9hGtrLxSMeta || {},
    component: () => import("/app/pages/forgot/index.vue")
  }
]
  },
  {
    name: "freemium-balancesheet",
    path: "/freemium/balancesheet",
    component: () => import("/app/pages/freemium/balancesheet.vue")
  },
  {
    name: "index",
    path: "/",
    component: () => import("/app/pages/index.vue")
  },
  {
    name: juridical3rL9bBYHCSMeta?.name,
    path: "/juridical",
    component: () => import("/app/pages/juridical.vue"),
    children: [
  {
    name: "juridical",
    path: "",
    component: () => import("/app/pages/juridical/index.vue")
  }
]
  },
  {
    name: libraryLAIl2gJcEoMeta?.name,
    path: "/library",
    component: () => import("/app/pages/library.vue"),
    children: [
  {
    name: "library",
    path: "",
    meta: indexxWYC1tMXRbMeta || {},
    component: () => import("/app/pages/library/index.vue")
  }
]
  },
  {
    name: loginhHM0vSTW5jMeta?.name,
    path: "/login",
    component: () => import("/app/pages/login.vue"),
    children: [
  {
    name: "login",
    path: "",
    meta: indexgK5VicK7y2Meta || {},
    component: () => import("/app/pages/login/index.vue")
  }
]
  },
  {
    name: "partner-accountants-id",
    path: "/partner-accountants/:id()",
    component: () => import("/app/pages/partner-accountants/[id].vue")
  },
  {
    name: "partner-accountants",
    path: "/partner-accountants",
    component: () => import("/app/pages/partner-accountants/index.vue")
  },
  {
    name: partnersEg1L314k67Meta?.name,
    path: "/partners",
    component: () => import("/app/pages/partners.vue"),
    children: [
  {
    name: "partners-id",
    path: ":id()",
    component: () => import("/app/pages/partners/[id].vue")
  },
  {
    name: "partners",
    path: "",
    component: () => import("/app/pages/partners/index.vue")
  }
]
  },
  {
    name: "payment",
    path: "/payment",
    meta: indexZu37Q4vm8IMeta || {},
    component: () => import("/app/pages/payment/index.vue")
  },
  {
    name: "previsional-balance",
    path: "/previsional-balance",
    meta: previsional_45balance1obdoJeiVYMeta || {},
    component: () => import("/app/pages/previsional-balance.vue")
  },
  {
    name: purchasesw2WIicYsI7Meta?.name,
    path: "/purchases",
    component: () => import("/app/pages/purchases.vue"),
    children: [
  {
    name: "purchases-auxiliaryLedger",
    path: "auxiliaryLedger",
    component: () => import("/app/pages/purchases/auxiliaryLedger/index.vue")
  },
  {
    name: "purchases-expense-notes",
    path: "expense-notes",
    component: () => import("/app/pages/purchases/expense-notes/index.vue")
  },
  {
    name: "purchases",
    path: "",
    meta: index6i7P1RXK56Meta || {},
    component: () => import("/app/pages/purchases/index.vue")
  },
  {
    name: "purchases-providers",
    path: "providers",
    component: () => import("/app/pages/purchases/providers/index.vue")
  }
]
  },
  {
    name: "registration-company",
    path: "/registration-company",
    meta: registration_45companyDBi9UNHdZyMeta || {},
    component: () => import("/app/pages/registration-company.vue")
  },
  {
    name: "reset-password",
    path: "/reset-password",
    meta: reset_45password52TOS9kgCUMeta || {},
    component: () => import("/app/pages/reset-password.vue")
  },
  {
    name: saleszmwAXRzZQBMeta?.name,
    path: "/sales",
    component: () => import("/app/pages/sales.vue"),
    children: [
  {
    name: "sales-auxiliaryLedger",
    path: "auxiliaryLedger",
    meta: indexujwFIL3o1vMeta || {},
    component: () => import("/app/pages/sales/auxiliaryLedger/index.vue")
  },
  {
    name: "sales-customers",
    path: "customers",
    component: () => import("/app/pages/sales/customers/index.vue")
  },
  {
    name: "sales-form-id",
    path: "form/:id()",
    meta: _91id_93xVIX6cb4UqMeta || {},
    component: () => import("/app/pages/sales/form/[id].vue")
  },
  {
    name: "sales",
    path: "",
    meta: indexgi1M0XIQhpMeta || {},
    component: () => import("/app/pages/sales/index.vue")
  },
  {
    name: "sales-payments",
    path: "payments",
    component: () => import("/app/pages/sales/payments/index.vue")
  },
  {
    name: "sales-products",
    path: "products",
    component: () => import("/app/pages/sales/products/index.vue")
  },
  {
    name: "sales-recovery",
    path: "recovery",
    component: () => import("/app/pages/sales/recovery/index.vue")
  },
  {
    name: "sales-ztickets",
    path: "ztickets",
    component: () => import("/app/pages/sales/ztickets/index.vue")
  }
]
  },
  {
    name: settingsLwEYOlkQxrMeta?.name,
    path: "/settings",
    component: () => import("/app/pages/settings.vue"),
    children: [
  {
    name: "settings-access",
    path: "access",
    component: () => import("/app/pages/settings/access/index.vue")
  },
  {
    name: "settings-annotations",
    path: "annotations",
    component: () => import("/app/pages/settings/annotations/index.vue")
  },
  {
    name: "settings-banks",
    path: "banks",
    component: () => import("/app/pages/settings/banks/index.vue")
  },
  {
    name: "settings-banks-manage",
    path: "banks/manage",
    component: () => import("/app/pages/settings/banks/manage.vue")
  },
  {
    name: "settings-billing-custom",
    path: "billing/custom",
    meta: customimgmJi6t9uMeta || {},
    component: () => import("/app/pages/settings/billing/custom.vue")
  },
  {
    name: "settings-billing",
    path: "billing",
    component: () => import("/app/pages/settings/billing/index.vue")
  },
  {
    name: "settings-chart-accounts",
    path: "chart-accounts",
    component: () => import("/app/pages/settings/chart-accounts/index.vue")
  },
  {
    name: "settings-ecommerce-idConnector-connexion",
    path: "ecommerce/:idConnector()/connexion",
    component: () => import("/app/pages/settings/ecommerce/[idConnector]/connexion/index.vue")
  },
  {
    name: "settings-ecommerce-idConnector-installation",
    path: "ecommerce/:idConnector()/installation",
    component: () => import("/app/pages/settings/ecommerce/[idConnector]/installation/index.vue")
  },
  {
    name: "settings-ecommerce",
    path: "ecommerce",
    component: () => import("/app/pages/settings/ecommerce/index.vue")
  },
  {
    name: "settings-exports",
    path: "exports",
    component: () => import("/app/pages/settings/exports/index.vue")
  },
  {
    name: "settings",
    path: "",
    meta: index11eXGkSJBcMeta || {},
    component: () => import("/app/pages/settings/index.vue")
  },
  {
    name: "settings-informations",
    path: "informations",
    component: () => import("/app/pages/settings/informations/index.vue")
  },
  {
    name: "settings-purchase-mail",
    path: "purchase-mail",
    component: () => import("/app/pages/settings/purchase-mail/index.vue")
  },
  {
    name: "settings-subscription",
    path: "subscription",
    component: () => import("/app/pages/settings/subscription/index.vue")
  }
]
  },
  {
    name: "shop",
    path: "/shop",
    component: () => import("/app/pages/shop.vue"),
    children: [
  {
    name: "shop-services-slug",
    path: "services/:slug()",
    component: () => import("/app/pages/shop/services/[slug].vue")
  },
  {
    name: "shop-services",
    path: "services",
    component: () => import("/app/pages/shop/services/index.vue")
  }
]
  },
  {
    name: "sso",
    path: "/sso",
    meta: ssoVLRRZJLQ6pMeta || {},
    component: () => import("/app/pages/sso.vue")
  },
  {
    name: "user-confirmed",
    path: "/user/confirmed",
    meta: confirmedLOxpKCDaeTMeta || {},
    component: () => import("/app/pages/user/confirmed.vue")
  },
  {
    name: vatfjWmeHf5yhMeta?.name,
    path: "/vat",
    component: () => import("/app/pages/vat.vue"),
    children: [
  {
    name: "vat-annex-form",
    path: "annex/form",
    component: () => import("/app/pages/vat/annex/form.vue")
  },
  {
    name: "vat-annex",
    path: "annex",
    component: () => import("/app/pages/vat/annex/index.vue")
  },
  {
    name: "vat-das2-form",
    path: "das2/form",
    component: () => import("/app/pages/vat/das2/form.vue")
  },
  {
    name: "vat-das2",
    path: "das2",
    component: () => import("/app/pages/vat/das2/index.vue")
  },
  {
    name: "vat-details-idvat",
    path: "details/:idvat()",
    meta: _91idvat_93FmWymG5Fr5Meta || {},
    component: () => import("/app/pages/vat/details/[idvat].vue")
  },
  {
    name: "vat-form",
    path: "form",
    component: () => import("/app/pages/vat/form.vue")
  },
  {
    name: "vat",
    path: "",
    meta: indexx4b9KVWT46Meta || {},
    component: () => import("/app/pages/vat/index.vue")
  }
]
  },
  {
    name: component_45stubsRK4GL8I6nMeta?.name,
    path: "/vat2",
    component: component_45stubsRK4GL8I6n
  },
  {
    name: component_45stubsRK4GL8I6nMeta?.name,
    path: "/sales2",
    component: component_45stubsRK4GL8I6n
  },
  {
    name: component_45stubsRK4GL8I6nMeta?.name,
    path: "/settings2",
    component: component_45stubsRK4GL8I6n
  },
  {
    name: component_45stubsRK4GL8I6nMeta?.name,
    path: "/balancesheet/action",
    component: component_45stubsRK4GL8I6n
  }
]