<template>
  <CommonsModalsModalBody height="auto" svg="CallWarningSvg">
    <template #title>
      <div>Aidez-nous à améliorer votre expérience</div>
    </template>

    <template #help>Pour toute réclamation, nos équipes sont là pour vous !</template>

    <template #content>
      <div class="modal-content">
        <div class="category-content">
          <uds-input-select-popper
            label="Catégorie"
            placeholder="Indiquer la catégorie"
            is-required
            :value="selectedCategory"
            :items="categories"
            item-value="id"
            item-text="text"
            :error="err.selectedCategory"
            @select="modifyCategory($event)"
          />

          <uds-input-select-popper
            ref="reasonInput"
            label="Motif"
            placeholder="Indiquer le motif"
            :value="selectedReason"
            is-required
            item-value="id"
            item-text="name"
            :items="reasons"
            :error="err.selectedReason"
            @select="selectedReason = $event"
          />
        </div>

        <uds-input-textarea
          :value="comment"
          :error="err.comment"
          label="Commentaire"
          is-required
          placeholder="Pour vous apporter une réponse précise, les détails de votre réclamation sont importants"
          @change="comment = $event.target?.value"
        />
        <CommonsDropzoneContainer
          :extensions="[
            'application/pdf',
            'image/png',
            'image/jpeg',
            'image/jpg',
            'text/csv',
            'text/plain',
          ]"
          :disabled="isLoading"
          :max-files="1"
          :max-size="20"
          @updated="file = $event[0]"
        />
      </div>
    </template>

    <template #footer>
      <uds-main-button type="tertiary" size="small" :disabled="isLoading" @click="$emit('close')">
        Annuler
      </uds-main-button>
      <uds-main-button
        size="small"
        :disabled="isLoading || !formIsValid"
        :loading="isLoading"
        @click="createReport"
      >
        Informez-nous
      </uds-main-button>
    </template>
  </CommonsModalsModalBody>
</template>

<script setup lang="ts">
import { reportExternalCategory } from "@silexpert/core";

const emit = defineEmits<{
  (e: "close" | "open"): void;
}>();

const societyStore = useSocietyStore();

const categories = computed(() => {
  return reportExternalCategory.map((category, index) => ({ text: category.label, id: index }));
});

const reasons = computed(() => {
  if (selectedCategory.value === null) return [];

  return reportExternalCategory[selectedCategory.value].options;
});

const reasonInput = ref();

const selectedCategory = ref<number | null>(null);
const selectedReason = ref<number | null>(null);
const comment = ref<string | null>(null);
const file = ref<File>();

function modifyCategory(value: number) {
  if (selectedReason.value !== null) {
    selectedReason.value = null;
    // reset the value of the input
    reasonInput.value.currentValue = "";
  }

  selectedCategory.value = value;
}

const err = computed(() => {
  return {
    selectedCategory: !isDefined(selectedCategory.value)
      ? "Veuillez sélectionner une catégorie"
      : null,
    selectedReason: !isDefined(selectedReason.value) ? "Veuillez sélectionner un motif" : null,
    comment:
      !isDefined(comment.value) || comment.value === "" ? "Veuillez saisir un commentaire" : null,
  };
});

const formIsValid = computed(() => {
  return !Object.values(err.value).some((error) => isDefined(error));
});

const isLoading = ref(false);
async function createReport() {
  isLoading.value = true;

  try {
    // Check if the selected category is an issue type
    const isIssueType =
      selectedCategory.value ===
      reportExternalCategory.findIndex(
        (category) => category.label === "Logiciel / Application client",
      );

    await $silex().report.createExternal({
      idIssueType: isIssueType ? selectedReason.value! : undefined,
      idReportType: isIssueType ? undefined : selectedReason.value!,
      idSociety: societyStore.society!.id!,
      comment: comment.value!,
      file: (file.value as any) ?? undefined,
    });

    $notifier().open({ type: "success", content: "Votre demande a bien été envoyée" });
    emit("close");
  } catch (error) {
    $notifier().open({ type: "error", content: apiErrorToString(error) });
    console.error(error);
  } finally {
    isLoading.value = false;
  }
}
</script>

<style lang="scss" scoped>
.modal-content {
  display: flex;
  flex-direction: column;
  gap: $uds-spacing-2;
}

.category-content {
  display: flex;
  width: 100%;
  gap: $uds-spacing-2;

  & > div {
    width: 100%;
  }
}
</style>
