<template>
  <div class="override-app-min-width">
    <!-- <DialogWarnBrowser :dialog="isDialogWarnBrowser" @close="isDialogWarnBrowser = false" /> -->
    <!-- <DialogConnect v-if="dialogConnect" :dialog="dialogConnect" /> -->
    <CommonsNotifier />
    <NuxtPwaManifest />
    <!-- <Notifier /> -->
    <div class="authenticate-layout-container">
      <div class="authenticate-svg-wrapper">
        <img :src="logoSrc" >
      </div>
      <div class="authenticate-content-wrapper">
        <slot />
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
const { isApple } = useDevice();

useHead({
  bodyAttrs: {
    class: isApple ? "" : "designed-scrollbar",
  },
});

const brandComposable = useBrandsComposable();
// Force production s3 for basic image
const hostS3: string = brandComposable.getClientBrandConfig().urlS3 as unknown as string;

const isXmasDate = computed(() => {
  const dayjs = useDayjs();

  const now = dayjs();
  const currentYear = now.year();

  const beforeXMas = dayjs(`${currentYear}/11/21`);
  const afterNewYear = dayjs(`${currentYear + 1}/01/15`);
  return now.isBetween(beforeXMas, afterNewYear);
});

const { isClementine, getBrandName } = useBrandsComposable();

try {
  if (window?.$crisp) {
    window.$crisp.push(["do", "chat:show"]);
    window.$crisp.push(["config", "position:reverse", [false]]);
  }
} catch (error) {
  console.error(error);
}

const logoSrc = computed<string>(() => {
  if (!hostS3) {
    return "";
  }

  const brand = getBrandName().toLocaleLowerCase();
  const suffixTarget = isXmasDate.value && isClementine() ? "noel" : "normal";
  const mimetype = brand === "anytime" ? "png" : "jpg";
  const logoSrc = `${hostS3}config/${brand}-${suffixTarget}.${mimetype}`;
  return logoSrc;
});
</script>

<style lang="scss" scoped>
.authenticate-layout-container {
  display: flex;
  height: 100vh;
  width: 100vw;
  background-color: $uds-white;
  .authenticate-svg-wrapper {
    display: flex;
    flex: 1;
    max-width: 50%;
    position: relative;
    margin: $uds-spacing-6 $uds-spacing-2 $uds-spacing-6 $uds-spacing-6;
    img {
      width: auto;
      height: 100%;
      object-fit: cover;
    }

    .become-ambassador {
      display: flex;
      position: absolute;
      bottom: 7%;
      left: 12%;
      line-height: 21px;

      > a {
        text-decoration: underline;
        color: $uds-white;
        font-size: 16px;
        font-weight: 100;
        cursor: pointer;
      }
    }
  }

  .authenticate-content-wrapper {
    display: flex;
    flex: 1;
    align-items: center;
    justify-content: flex-start;
    :deep(.content-wrapper) {
      display: flex;
      height: 100vh;
      align-items: center;
      justify-content: center;
      position: relative;
      .content-last {
        text-align: center;
        position: absolute;
        bottom: $uds-spacing-6;
        left: 0px;
        font-size: 14px;
        width: 100%;
        color: $uds-neutral-700;
      }
    }
  }
}

@media screen and (max-width: 1300px) {
  .authenticate-svg-wrapper {
    display: none !important;
  }

  .authenticate-content-wrapper {
    justify-content: center !important;
  }
}
</style>
