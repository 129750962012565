<template>
  <div
    id="dropzone-container"
    ref="dropZoneRef"
    :class="{ active: isOverDropZone }"
    style="position: relative"
    class="scrollbar"
  >
    <div
      v-if="!isLoading"
      style="align-items: center; justify-items: center; text-align: center; width: 100%"
      @click="openFileDialog()"
    >
      <uds-icon class="cloud_icon" icon-name="cloud_upload" size="xlarge" />
      <div class="uds-paragraph text-neutral">Déposer / Glisser ici vos factures d'achats</div>
    </div>

    <div v-else class="upload-loading">
      <div class="custom-loader" />
    </div>
  </div>
</template>

<script setup lang="ts">
import * as Sentry from "@sentry/vue";
import { AccountingFileType } from "@silexpert/core";

const societyStore = useSocietyStore();
const annotateStore = useAnnotateStore();
const dayjs = useDayjs();

const {
  open: openFileDialog,
  onChange: onChangeFileDialog,
  reset: resetFileDialog,
} = useFileDialog({
  multiple: true,
  accept: basicImageAndPdfMimeType.join(),
  directory: false,
});

onChangeFileDialog((fileDialogFiles) => {
  if (!fileDialogFiles || fileDialogFiles.length === 0) return;

  // Convert FileList to File[]
  const files: File[] = [];
  for (let i = 0; i < fileDialogFiles.length; i++) {
    files.push(fileDialogFiles.item(i) as File);
  }
  uploadFile(files);
});

const dropZoneRef = ref<HTMLElement>();
const { isOverDropZone, files: dropZoneFiles } = useDropZone(dropZoneRef, {
  onDrop() {
    if (!dropZoneFiles.value || dropZoneFiles.value.length === 0) return;

    uploadFile(dropZoneFiles.value);
  },
});

const isLoading = ref(false);
function uploadFile(files: File[]) {
  isLoading.value = true;
  try {
    Sentry.captureMessage("quick_upload_from_drawer");
    files.forEach((file) => {
      $silex().accountingFiles.createAccrualAccountingFile({
        idSociety: societyStore.society?.id || 0,
        files: [file],
        idAccountingType: AccountingFileType.PURCHASE_INVOICES,
        split: false,
        isPayPerso: false,
        bypassOcr: false,
        emissionDate: dayjs().format("YYYY-MM-DD"),
        withSocketReturn: true,
      });
    });
  } catch (error) {
    $notifier().open({ type: "error", content: apiErrorToString(error) });
  } finally {
    isLoading.value = false;
    resetFileDialog();
    dropZoneFiles.value = null;
    annotateStore.$patch({
      status: "pending",
    });
  }
}
</script>

<style lang="scss" scoped>
#dropzone-container {
  margin: $uds-spacing-2 $uds-spacing-2 0 $uds-spacing-2;
  height: 148px;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  overflow: auto;
  border: 1px dashed $uds-neutral-600 !important;
  background: #f1f2f4;
  border-radius: 5px;
  flex-direction: column;

  .cloud_icon {
    margin-bottom: 10px;
    color: $uds-neutral-700;
  }
  .subtitle {
    margin-top: 5px;
    p {
      text-align: center;
      color: $uds-neutral-800;
      margin-bottom: 0px;
      margin-top: 0px;
    }
  }

  &:hover:not(.disabled),
  &.active:not(.disabled) {
    cursor: pointer;
    background-color: $uds-primary-50;
    transition: 0.3s;
    .cloud_icon {
      color: $uds-primary-300;
    }
  }

  &.error {
    border: 1px solid $uds-error-300 !important;
  }

  &.disabled {
    opacity: 0.7;
    cursor: not-allowed;
  }
}

.upload-loading {
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px dashed $uds-primary-100;
  background: $uds-primary-50;
  border-radius: 5px;
  height: 148px;
  width: 100%;

  .custom-loader {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    border: 4px solid;
    border-color: $uds-primary-300;
    border-right-color: $uds-primary-500;
    animation: s2 1s infinite linear;
  }

  @keyframes s2 {
    to {
      transform: rotate(1turn);
    }
  }
}

.text-neutral {
  color: $uds-neutral-800;
}
</style>
